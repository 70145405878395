@import './_breakpoints.scss';

body {
  font-family: $font-family-brandon;
  color: $color-content;
  font-weight: $font-weight-normal;
}

// SVGs aren't the correct size due to line-height/font-size issues w/o this
svg {
  display: block;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
